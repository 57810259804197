.hidden {
  display: none;
}

/**/
.option {
  padding: 15px;
  white-space: nowrap;
  cursor: pointer;
}

.option:hover {
  background-color: #bebebe;
}

/*.dropdown span {
    margin-right: 0;
    display: block;
  }*/
/* ------------------- Dropdown */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown .dropdown_content {
  display: none;
  position: absolute;
  background-color: var(--home);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  white-space: nowrap;
  width: 230px;
}

.dropdown:hover .dropdown_content {
  display: block;
}

/* Subdropdown */

/*.dropdown .dropdown_content:hover {
  background-color: var(--green-light);
}
*/
/*------------------- Opção do menu*/

.dropdown_content .dropdown_link {
  position: relative;
  cursor: pointer;
}

.dropdown_link {
  border-top: 1px solid rgba(0, 0, 0, 0.411);
}

.dropdown_content .dropdown_link a {
  display: block;
  padding: 12px 16px;
}

.dropdown_content .dropdown_link:hover {
  background-color: #bebebe;
}

.dropdown_content .sub_dropdown {
  position: relative;
  cursor: pointer;
}

.sub_dropdown {
  border-top: 1px solid rgba(0, 0, 0, 0.411);
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown_content .sub_dropdown a {
  display: block;
  padding: 12px 16px;
}

.dropdown_content .sub_dropdown .sub_dropdown_content {
  position: absolute;
  display: none;
  left: 100%;
  top: 0;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  background-color: #f9f9f9;
}

/*.dropdown .dropdown_content .sub_dropdown .sub_dropdown_content span {
    background-color: #bebebe;
  } */
.dropdown_content .sub_dropdown:hover {
  background-color: #bebebe;
}

.dropdown_content .sub_dropdown:hover .sub_dropdown_content {
  display: block;
}