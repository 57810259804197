.container_grid {
    display: grid;

    /* grid-template-rows: 1fr 1fr; */
    grid-template-areas: "filtros" "coletas";
}


.filtros {
    grid-area: filtros;
    /* background-color: rgb(0, 255, 0); */
    margin-bottom: 15px;
}

.coletas {
    grid-area: coletas;
    background-color: rgb(255, 255, 0);
}