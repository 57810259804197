.container_grid {
    /* margin: 0 auto; */
    /* padding: 5px; */
    display: grid;
    /* grid-template-columns: minmax(200px, 400px) minmax(500px, auto); */
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "header"
        "footer";
    /* background-color: rgba(127, 131, 255, 0.338); */
    gap: 10px;


}



.header {
    display: grid;
    grid-template-columns: minmax(200px, 400px) minmax(500px, auto);
    grid-area: header;
    /* background-color: #48CFE0; */
    grid-template-areas: "rota listarota";
    gap: 10px;

}

.rota {
    grid-area: rota;
    /* background-color: #E0D475; */

}

.listarota {
    grid-area: listarota;
    /* background-color: #374cc4; */
}

.footer {
    display: grid;
    /* grid-template-rows: 1fr; */
    /* grid-template-columns: 1fr 1fr 1fr; */
    grid-template-areas: "estimativageral materiais estimativamotorista";


}

.estimativageral {
    grid-area: estimativageral;
    background-color: rgb(248, 248, 75);
}

.materiais {
    grid-area: materiais;
    background-color: rgb(135, 233, 23);
}

.estimativamotorista {
    grid-area: estimativamotorista;
    background-color: rgb(216, 55, 23);
}