label {
    font-family: sans-serif;
    font-size: 1rem;
    padding-right: 10px;
}

select {
    font-size: 0.9rem;
    padding: 2px 5px;
}



.button {
    margin-bottom: 10px;
}

.filtro {
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    z-index: 5
}